<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="newPageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col" v-if="isCustomerRole">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="isCustomerRole">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-number="'numberAndCharC'"
                                  v-model="searchForm.userId"
                                  @blur="handleBlur"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col" v-if="!isCustomerRole">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-number="'numberAndCharC'"
                                  v-model="searchForm.userId"
                                  @blur="handleBlur"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-show="isCustomerRole">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="50"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ポイント種別</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="type"
                                  v-model="searchForm.type"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in historyPointTypeList"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>発行元店舗</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <SearchSelect
                                ref="searchSelect"
                                singleLabel="name"
                                :options="shopPointList"
                                :multiple="false"
                                :closeOnSelect="true"
                                :searchKeyMinLength="searchKeyLength"
                                :searchAction="searchActionShopPoint"
                                :resetOptions="resetOptionShopPoint"
                                :params="shopParams"
                                :searchField="searchField"
                                @change-selection="handleSelectedShop"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>QRコード</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="qrCode"
                                  v-model="searchForm.qrCode"
                                  @mousedown="selectedQrCode = false"
                                  @focus="selectedQrCode = false"
                                  @change="onChangeQR"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in pointQRCodePulldown"
                                    :key="item.id"
                                    :value="item.id"
                                    :class=" { 'qrCategory': !item.isChild }"
                                    :id="item.id"
                                  >
                                    <span>
                                      {{ getSelectedName(item) }}
                                    </span>
                                  </option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>利用期間</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="usedStartDate"
                                      v-model="searchForm.usedStartDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="usedEndDate"
                                      v-model="searchForm.usedEndDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>有効期限</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="expiredStartDate"
                                      v-model="searchForm.expiredStartDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="expiredEndDate"
                                      v-model="searchForm.expiredEndDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>ポイント合計</template>
                  <template v-slot:num>{{ historyPoint | comma }}</template>
                  <template v-slot:sup>P</template>
                  <template v-slot:sub>
                    <span class="numDisplay-sub-sup">加算予定ポイント</span>{{ historyPlannedPoint }}P
                  </template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :data-table="dataTable"
                    :labels="labels"
                    :storeModule="storeModule"
                    :count="historyPointCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="PaginationLog"
                  :listLength="historyPointCount"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!historyPointList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
//mixins
import flatpickr from '@/mixins/plugin/flatpickr';
import nav from '@/mixins/nav/point';
import search from '@/mixins/plugin/search';
import sort from '@/mixins/plugin/sort';
import download from '@/mixins/plugin/download';
//components
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
import SearchSelect from '@/components/SearchSelect.vue';
//helpers
import { formatLocalString, formatDateAndTime, endline } from '@/helpers/formatData';
//constants
import { StatusConstants } from '@/constants/status';
import { CommonConstants } from '@/constants/common'

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      parentModulePath: 'parentPoint/getHistoryPointList',
      childModulePath: 'childPoint/getHistoryPointList',
      searchConditions: {},
      searchFields: ['name', 'email', 'userId', 'type', 'shopId', 'qrCode', 'usedStartDate', 'usedEndDate', 'expiredStartDate', 'expiredEndDate', 'categoryId'],
      storedField: 'idRequestExportHistoryPoint',
      selectedQrCode: false,
      searchField: 'shopId',
      endline,
    };
  },
  mixins: [nav, search, download, flatpickr, sort],
  components: {
    FormRow,
    NumDisplay,
    TableWithCheckbox,
    PaginationLog,
    Flatpickr,
    ModalConfirmDownload,
    SearchSelect
  },
  computed: {
    ...mapGetters({
      parentHistoryPointList: 'parentPoint/historyPointList',
      childHistoryPointList: 'childPoint/historyPointList',
      parentHistoryPointCount: 'parentPoint/historyPointCount',
      childHistoryPointCount: 'childPoint/historyPointCount',
      parentHistoryPoint: 'parentPoint/historyPoint',
      childHistoryPoint: 'childPoint/historyPoint',
      parentHistoryPlannedPoint: 'parentPoint/historyPlannedPoint',
      childHistoryPlannedPoint: 'childPoint/historyPlannedPoint',
      hasCustomersRole: 'auth/hasCustomersRole',
      pointQRCodePulldown: 'parentPoint/pointQRCodePulldown',
      historyPointTypeList: 'parentPoint/historyPointTypeList',
      shopPointList: 'parentPoint/shopPointList',
      hasShopRole: 'auth/hasShopRole',
      infor: 'auth/infor',
      isShowCustomer: 'event/isShowCustomer',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      isGmoOrOfficeUrl: 'common/isGmoOrOfficeUrl',
    }),
    directory() {
      return this.$route.params?.directory;
    },
    newPageNavs() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? [] : this.pageNavs;
    },
    historyPlannedPoint() {
      return this.isPointCommon ? this.parentHistoryPlannedPoint : this.childHistoryPlannedPoint;
    },
    historyPoint() {
      return (this.isPointCommon ? this.parentHistoryPoint : this.childHistoryPoint) || 0;
    },
    historyPointCount() {
      return this.isPointCommon ? this.parentHistoryPointCount : this.childHistoryPointCount;
    },
    historyPointList() {
      return this.isPointCommon ? this.parentHistoryPointList : this.childHistoryPointList;
    },
    isCustomerRole() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? this.isShowCustomer : this.hasCustomersRole;
    },
    routeNameDetail() {
      if (this.isClientChild) return 'EventUserDetail';
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) return 'ShopUserDetail';
      return 'EventUserEdit';
    },
    dataTable() {
      return this.historyPointList.map(item => {
        return {
          ...item,
          userId: item?.customerNumber,
          name: {
            content: !item?.isDeletedCustomer ? endline(item?.name, 20) : '退会ユーザー',
            routeName: this.routeNameDetail,
            params: {
              ...(this.isGmoOrOfficeLoginStoreAndStoreGroup ? { id: item?.customerId } : { customerId: item?.customerId }),
            },
            isLink: !item?.isDeletedCustomer,
          },
          amountPoint: {
            content: this.checkAmountPoint(item),
            color: this.checkColorPoint(item)
          },
          shopId: endline(item?.shop, 15),
          createDate: this.checkCreateDatePoint(item),
          expirationDate: formatDateAndTime(item?.expirationDate),
          amountMoney: item?.amountMoney ? (formatLocalString(item?.amountMoney) + '円') : '',
          buttonLinks: [
          {
              content: '詳細',
              class: 'btn btn-sm btn-bd-main',
              function: () => this.redirectToEventUser(item),
              isNotShown: item?.isDeletedCustomer || !this.hasCustomersRole || this.isGmoOrOfficeLoginStoreAndStoreGroup || this.isClientChild || item.onlyOmise
            },
          ],
        };
      });
    },
    labels() {
      return [
        { key: 'userId', name: 'user ID' },
        { key: 'name', name: 'お名前', notShow: !this.isCustomerRole, tdClass: 'pre-line' },
        { key: 'type', name: 'ポイント種別' },
        { key: 'amountPoint', name: 'ポイント数' },
        { key: 'shopId', name: '対象店舗', tdClass: 'pre-line' },
        { key: 'createDate', name: '日時', sortKey: 'createDateNew' },
        { key: 'expirationDate', name: '有効期限', sortKey: 'expirationDate' },
        { key: 'amountMoney', name: '対象金額' },
        ...((this.hasCustomersRole || !this.isGmoOrOfficeLoginStoreAndStoreGroup) && !this.isClientChild) ? [{ isButtonColumn: true }] : [],
      ];
    }
  },
  methods: {
    redirectToEventUser(item) {
      this.$router.push({
        name: this.routeNameDetail,
        params: {
          ...(this.isGmoOrOfficeLoginStoreAndStoreGroup ? { id: item?.customerId } : { customerId: item?.customerId }),
        },
      })
    },
    getSelectedName(item) {
      return ((item?.isChild && !this.selectedQrCode) ? '└' : '') + item.name;
    },
    checkAmountPoint(item) {
      if (item.type === StatusConstants.pointStatus.addedIntendPoint.label) {
        return `(${formatLocalString(item?.amountPoint)}P)`;
      }
      return (!item?.plus ? `-${formatLocalString(item?.amountPoint)}` : formatLocalString(item?.amountPoint)) + 'P';
    },
    checkCreateDatePoint(item) {
      if (item.type === StatusConstants.pointStatus.addedIntendPoint.label) {
        return `(${formatDateAndTime(item?.createDate)})`;
      }
      return formatDateAndTime(item?.createDate);
    },
    checkColorPoint(item) {
      return (!item?.plus && StatusConstants.pointStatus.addedIntendPoint.label) ? 'text-red' : 'tox';
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch(`${this.storeModule}/getHistoryPointCSV`, {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    handleSelectedShop(item) {
      this.searchForm.shopId = item?.value;
    },
    handleBlur(e) {
      if (!CommonConstants.NUMBER_AND_C_VALIDATION_REGEX.test(e.target.value)) {
        e.target.value = '';
        this.searchForm.userId = '';
      }
    },
    onChangeQR(event) {
      const options = event.target.options;
      const selectedIdx = event.target.selectedIndex;
      const selectedClassName = options[selectedIdx].className;
      this.searchForm.categoryId = (selectedClassName === 'qrCategory') ? event.target.value : null;
      this.selectedQrCode = true;
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getSelectDataList('getPointQrCodePullDown'),
      this.getSelectDataList('getHistoryPointTypeList'),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
};
</script>
